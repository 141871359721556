import React from 'react';

import logo from '../assets/images/logo.png';

const Header = props => (
  <header id="header" className="alt">
    <span className="logo">
      <img src={logo} alt="" />
    </span>
    <h1>EVMU</h1>
    <p>Introducing a modern, feature complete Dreamcast VMU Emulator</p>
  </header>
);

export default Header;
