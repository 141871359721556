import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Waypoint from 'react-waypoint';

import Layout from '../components/layout';
import Header from '../components/Header';
import Nav from '../components/Nav';
import EVMU from '../components/EVMU';

import icon0 from '../assets/images/volume_icons/1.png';
import icon1 from '../assets/images/volume_icons/65.png';
import icon2 from '../assets/images/volume_icons/0.png';
import icon3 from '../assets/images/volume_icons/4.png';
import icon4 from '../assets/images/volume_icons/5.png';
import icon5 from '../assets/images/volume_icons/79.png';
import icon6 from '../assets/images/volume_icons/37.png';
import icon7 from '../assets/images/volume_icons/6.png';
import icon8 from '../assets/images/volume_icons/2.png';
import icon9 from '../assets/images/volume_icons/47.png';
import icon10 from '../assets/images/volume_icons/28.png';
import icon11 from '../assets/images/volume_icons/39.png';
import icon12 from '../assets/images/volume_icons/61.png';
import icon13 from '../assets/images/volume_icons/15.png';
import icon14 from '../assets/images/volume_icons/56.png';
import icon15 from '../assets/images/volume_icons/51.png';
import icon16 from '../assets/images/volume_icons/48.png';
import icon17 from '../assets/images/volume_icons/52.png';

// import platformAndroid from '../assets/images/platform_0002_android.png';
// import platformIOS from '../assets/images/platform_0003_ios2.png';
import platformLinux from '../assets/images/platform_0004_linux.png';
import platformMac from '../assets/images/platform_0005_mac.png';
import platformWindows from '../assets/images/platform_0006_windwos.png';
import platformPI from '../assets/images/platform_0007_pi.png';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false,
    };
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    return (
      <Layout>
        <Helmet title="Elysian VMU" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        />
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Elysian Visual Memory Unit</h2>
                </header>
                <p>
                  The Elysian VMU is an under-development VMU emulator that
                  attempts to go far beyond the call of a simple emulator with
                  fancy modern features. EVMU is being designed to be the
                  end-all, one-stop VMU emulation, file management, and
                  development software platform.
                </p>
                <ul className="actions">
                  <li>
                    <Link to="#cta" className="button special">
                      Download
                    </Link>
                  </li>
                  <li>
                    <Link to="/play" className="button">
                      Play Online
                    </Link>
                  </li>
                </ul>
              </div>
              <EVMU />
            </div>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>About</h2>
            </header>
            <p className="content">
              Being developed by the team behind{' '}
              <a href="https://elysianshadows.com" target="_blank">
                Elysian Shadows
              </a>{' '}
              in parallel with the game itself, EVMU seeks to not only fully and
              accurately reproduce the glorious 8-bit gaming experience of the
              past, but also to allow future indie games to turn any PC,
              smartphone, or mobile device into a virtual VMU to use as an
              additional display, save device, or external 8-bit gaming
              platform, functioning as it did on the Dreamcast. EVMU is also
              being developed to function as an add-on emulator for existing
              Dreamcast emulators, to bring you the COMPLETE Dreamcast emulation
              experience.
            </p>
            <p className="content">
              ElysianVMU aims to unify the VMU community for gamers and for developers, by offering a single,
              integrated tool which can be used to unlock the full potential of the device on any platform,
              by honoring it as an interactive 8-bit filesystem for save files, as a standalone gaming device,
              as a remote screen for Dreamcast homebrew and emulators, and as an epic
              little microcontroller platform capable of powering indie titles.
            </p>
            <p className="content">
              ElysianVMU aims to unify the VMU community for gamers and for developers, by offering a single,
              integrated tool which can be used to unlock the full potential of the device on any platform,
              by honoring it as an interactive 8-bit filesystem for save files, as a standalone gaming device,
              as a remote screen for Dreamcast homebrew and emulators, and as an epic
              little microcontroller platform capable of powering indie titles.
            </p>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Why The VMU?</h2>
            </header>
            <p className="content">
              So you're a game developer or an an engineer and think it looks kinda neat but are wondering
              why the hell you should actually realistically consider the VMU as a development platform.
              Allow me to share with you the variety of reasons why I, as a developer and
              as an engineering enthusiast, see the VMU as an appealing target platform.
            </p>
            <ul className="features">
              <li>
                <div className="icon major style1">
                  <img src={icon0}/>
                </div>
                <p>Cross-Platform: Windows, MacOS, Linux, Raspberry Pi</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon1}/>
                </div>
                <p>Japanese and US Bios Support</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon2}/>
                </div>
                <p>Filesystem and Save File Manager</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon3}/>
                </div>
                <p>Export and Import .VMI/.VMS, .DCI, .VMU, .DCM and .BIN file formats</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon4}/>
                </div>
                <p>Supports .LCD VMU Animator files</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon5}/>
                </div>
                <p>Custom Graffiti Tool for Jet Set/Grind Radio</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon6}/>
                </div>
                <p>Buzzer Tool for Creating VMU Music</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon7}/>
                </div>
                <p>Controller/Joystick Support (with Analog Stick support)</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon8}/>
                </div>
                <p>Physically Accurate LCD Animation (for grayscale and pixel ghosting)</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon9}/>
                </div>
                <p>Tools for exporting and ripping Dreamcast file icons</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon10}/>
                </div>
                <p>Screenshot and Screen Capture Recording</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon11}/>
                </div>
                <p>Supports VMU-to-VMU Serial Emulation over IP (work-in-progress)</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon12}/>
                </div>
                <p>Memory Browser and Debug Environment (work-in-progress)</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon13}/>
                </div>
                <p>Integrated VMU Assembler and Disassembler (Waterbear)</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon14}/>
                </div>
                <p>Fully Integrated Documentation</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon15}/>
                </div>
                <p>Low-Battery Emulation</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon16}/>
                </div>
                <p>Scriptable Command-Line Interface</p>
              </li>
              <li>
                <div className="icon major style1">
                  <img src={icon17}/>
                </div>
                <p>Fully Supported by the Elysian Shadows Engine and Toolkit</p>
              </li>
            </ul>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Get EVMU</h2>
              <p>
                EVMU is currently in active development, but you can try out an
                early build below or <Link to="/play">play online</Link>.
              </p>
            </header>

            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="evmu-builds/ElysianVMU_Win_x86.zip">
                    <img src={platformWindows} />
                  </a>
                </li>
                <li>
                  <a href="evmu-builds/ElysianVMU_Debian_x64.zip">
                    <img src={platformLinux} />
                  </a>
                </li>
                <li>
                  <a href="evmu-builds/ElysianVMU_MacOS.zip">
                    <img src={platformMac} />
                  </a>
                </li>
                <li>
                  <a href="evmu-builds/ElysianVMU_RaspberryPi.zip">
                    <img src={platformPI} />
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Index;
