import React from 'react';
import { Link } from 'gatsby';

import logo from '../assets/images/logo.png';

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Our Work</h2>
      <p>
        Elysian Shadows is an indie 2D RPG being developed for Windows, OS X,
        Linux, Android, iOS, Ouya and Sega Dreamcast. EVMU is built ontop of the
        libGyro framework, and will tie directly into Elysian Shadows.
      </p>
      <ul className="actions">
        <li>
          <a
            href="http://elysianshadows.com/"
            className="button"
            target="_blank"
          >
            Learn More
          </a>
        </li>
      </ul>
    </section>
    <section>
      <h2>Get in touch</h2>
      <dl className="alt">
        <dt>Discord</dt>
        <dd>
          <a href="https://discord.gg/7uDxdGh">https://discord.gg/7uDxdGh</a>
        </dd>

        <dt>Email</dt>
        <dd>
          <a href="#">team@elysianshadows.com</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/elysian_shadows"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://facebook.com/elysianshadows"
            className="icon fa-facebook alt"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://plus.google.com/+Elysianshadows"
            className="icon fa-instagram alt"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/user/GyroVorbis"
            className="icon fa-youtube alt"
          >
            <span className="label">YouTube</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">&copy; Elysian Shadows Team.</p>
  </footer>
);

export default Footer;
