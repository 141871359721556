import React from 'react';
import FileDrop from './FileDrop';
import { withPrefix } from "gatsby"

import vmuBackground from '../assets/images/VMU_Bezel.png';

// const EVMU_CORE_JS = withPrefix('evmu-core/build/web/bin/evmu.js');
const EVMU_CORE_JS = withPrefix('evmu-core-prebuilt/evmu.js');
const VMU_DISP_PIXEL_HEIGHT = 32;
const VMU_DISP_PIXEL_WIDTH = 48;
const FRAMERATE = 30;

const nextAnimFrame = function(callback) {
  window.setTimeout(callback, 1.0 / FRAMERATE);
};

class EVMU extends React.Component {
  loadRom(file) {
    this.gyVmu.flashFormatDefault();
    this.gyVmu.flashRootBlockPrint();
    this.gyVmu.flashLoadImage(file);
    this.gyVmu.resetCPU();
  }

  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false,
    };
  }

  renderVMU() {
    if (this.canvas && this.gyVmu) {
      const ctx = this.canvas.getContext('2d');
      this.gyVmu.deviceUpdate(1.0 / FRAMERATE);

      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      const pixelScale = Math.max(
        Math.floor(this.canvas.width / VMU_DISP_PIXEL_WIDTH),
        Math.floor(this.canvas.height / VMU_DISP_PIXEL_HEIGHT)
      );

      for (var x = 0; x < VMU_DISP_PIXEL_WIDTH; x++) {
        for (var y = 0; y < VMU_DISP_PIXEL_HEIGHT; y++) {
          var pixel = this.gyVmu.displayPixelGet(x, y);
          if (pixel) {
            ctx.fillStyle =
              'rgba(' + pixel + ', ' + pixel + ', ' + pixel + ', 255)';
            ctx.fillRect(
              x * pixelScale,
              y * pixelScale,
              pixelScale,
              pixelScale
            );
          }
        }
      }

      nextAnimFrame(this.renderVMU.bind(this));
    }
  }

  onModuleLoaded() {
    this.module.isLoaded = true;
    this.module.FS.mkdir('./roms');
    this.module.FS.mount(
      this.module.FS.filesystems.MEMFS,
      { root: './roms' },
      './roms'
    );
    this.onReadyToUse();
  }

  loadFromFromUrl(fileName) {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        console.log(xhttp);
        var data = new Uint8Array(xhttp.response);
        try {
          this.module.FS_createDataFile('/', fileName, data, true, true, true);
        } catch (e) {}
        this.loadRom(fileName);
      }
    };
    xhttp.responseType = 'arraybuffer';
    xhttp.open('GET', withPrefix(fileName), true);
    xhttp.send();
  }

  componentDidMount() {
    this.canvas = document.getElementById('evmu');
    if (this.canvas) {
      if (window.Module) {
        this.inheritModuleFromWindow();
      } else {
        var script = document.createElement('script');
        script.onload = this.inheritModuleFromWindow.bind(this);
        script.src = EVMU_CORE_JS;
        document.head.appendChild(script);
      }
    }
  }

  inheritModuleFromWindow() {
    this.module = window.Module;
    this.module.canvas = this.canvas;

    if (this.module.isLoaded) {
      this.onReadyToUse();
    } else {
      this.module.onRuntimeInitialized = this.onModuleLoaded.bind(this);
    }
  }

  onReadyToUse() {
    this.module.gyInit();
    this.gyVmu = new this.module.VMUWrapper();
    this.gyVmu.deviceCreate();
    this.loadFromFromUrl('roms/pacman.DCI');
    this.renderVMU();
  }

  componentWillUnmount() {
    this.module.gyUninit();
    delete this.canvas;
    delete this.gyVmu;
  }

  handleDrop = (files, event) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      var fr = new FileReader();
      fr.onload = () => {
        var data = new Uint8Array(fr.result);
        try {
          this.module.FS_createDataFile('/', file.name, data, true, true, true);
        } catch (e) {}
        this.loadRom(file.name);
      };
      fr.readAsArrayBuffer(file);
    }
  };

  render() {
    return (
      <FileDrop onDrop={this.handleDrop} className="vmu-container">
        <img src={vmuBackground} alt="" />
        <canvas id="evmu" width="144px" height="96px" />
      </FileDrop>
    );
  }
}

export default EVMU;
